let lazyImage = (images) => {
    if (!images) return;
    images.forEach(img => {

        let isError = false;
        let originalSrc = img.getAttribute('src');

        img.addEventListener('error', () => {
            if (isError) return;
            isError = true;
            img.src = originalSrc;
        });

        img.src = img.dataset.src;

    });
}

export default lazyImage