const searchSelector = 'search';
const searchFormSelector = 'search__form';
const searchInputSelector = 'search__input';
const suggestSelector = 'suggest';
const suggestContainerSelector = 'search__suggest';
const suggestsItemSelector = 'suggest__item';
const suggestItemActiveSelector = 'suggestItem_active';
const suggestItemSelector = 'suggestItem';
const suggestItemImgSelector = 'suggestItem__img';
const suggestItemTitleSelector = 'suggestItem__title';


const searchEl = document.querySelector('.' + searchSelector);

if (searchEl) {

    const searchFormEl = document.querySelector('.' + searchFormSelector);
    const suggestContainerEl = searchEl.querySelector('.' + suggestContainerSelector);
    const inputEl = searchEl.querySelector('.' + searchInputSelector);
// const suggestUrl = inputEl.dataset.suggests;
    const suggestUrl = '/ajax/suggest/';

    let isActive = false;
    let activeIndex = null;
    let activeSuggestDelay = null; // timeoutContainer
    let oldValue = inputEl.value;

    let suggestEl = document.createElement('div');
    suggestEl.classList.add(suggestSelector);

    inputEl.addEventListener('keydown', (e) => {
        if (e.keyCode === 13) {
            if (activeIndex !== null) {
                e.preventDefault();
                let suggestItemsEl = suggestEl.querySelectorAll('.' + suggestItemSelector);
                suggestItemsEl[activeIndex].click();
            }
        }
    })

    inputEl.addEventListener('keyup', (e) => {

        let suggestItemsEl;

        switch (e.keyCode) {
            case (13): // enter
            case (27): // esc
                clearSuggest(e);
                inputEl.blur();
                break;
            case (38): // up
                suggestItemsEl = suggestEl.querySelectorAll('.' + suggestItemSelector);

                if (activeIndex === null) {
                    activeIndex = suggestItemsEl.length - 1;
                } else {
                    suggestItemsEl[activeIndex].classList.remove(suggestItemActiveSelector);
                    if (activeIndex-- === 0) {
                        activeIndex = suggestItemsEl.length - 1;
                    }
                }

                suggestItemsEl[activeIndex].classList.add(suggestItemActiveSelector);

                // suggestItemsEl[activeIndex].scrollIntoView({
                //     behavior: "smooth",
                //     block: "center"
                // });

                break;
            case (40): // down
                suggestItemsEl = suggestEl.querySelectorAll('.' + suggestItemSelector);

                // activeIndex = activeIndex===null || activeIndex === suggestItemsEl.length ? 0 : ++activeIndex

                if (activeIndex === null) {
                    activeIndex = 0;
                } else {
                    suggestItemsEl[activeIndex].classList.remove(suggestItemActiveSelector);
                    if (++activeIndex === suggestItemsEl.length) {
                        activeIndex = 0;
                    }
                }

                suggestItemsEl[activeIndex].classList.add(suggestItemActiveSelector);

                // suggestItemsEl[activeIndex].scrollIntoView({
                //     behavior: "smooth",
                //     block: "center"
                // });

                break;
            default:

                (oldValue !== inputEl.value) && updateSuggest(e);
                break;
        }

        oldValue = inputEl.value;

    });

    inputEl.addEventListener('focus', (e) => {
        clear = false;
        activateSuggest(e);

    })

    let clear = true;

    document.addEventListener('click', () => {
        disableSuggest();
    });

    searchEl.addEventListener('click', () => {
        clear = false;
    });

    function activateSuggest(e) {
        isActive = true;
        updateSuggest(e);
    }

    function disableSuggest() {

        // console.log(clear);

        if (clear) {
            isActive = false;
            activeIndex = false;
            clearSuggest();
        }
        clear = true;
    }

    function clearSuggest() {

        suggestEl.innerHTML = '';
        suggestEl.remove();
        clearTimeout(activeSuggestDelay);

    }

    function updateSuggest(event = {}) {

        clearTimeout(activeSuggestDelay);
        activeIndex = null;

        // проверка на пустой инпут
        if (!inputEl.value && inputEl.value !== '0') {
            return clearSuggest();
        }

        activeSuggestDelay = setTimeout(() => {

            let inputValue = encodeURI((inputEl.value).replace(/ /g, '+'));

            fetch(suggestUrl + inputValue + '?ts=' + (new Date()).getTime(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-requested-with': 'XMLHttpRequest'
                },

            })
                .then(response => response.json())
                .then(data => {
                    isActive && buildSuggestsFromFetch(data);
                })

        }, 0);

    }

    function buildSuggestsFromFetch(data) {

        let suggestItems = data.items || [];

        let renderItems = [];

        suggestEl.innerHTML = '';

        // подготавливаем и групперуем
        suggestItems.forEach((suggestItem) => {

            let suggestsItemEl = getSuggestItemDomEl(suggestItem);

            renderItems.push(suggestsItemEl);

        });

        let limit = renderItems.length;

        let i = 0;
        while (i < limit) {
            suggestEl.appendChild(renderItems[i]);
            i++;
        }

        // подключаем рендер в конейнер саджестов
        suggestContainerEl.appendChild(suggestEl);
    }


    function getSuggestItemDomEl(suggestItem) {

        let imageLink = suggestItem.cover;

        let suggestsItemEl = document.createElement('div');
        suggestsItemEl.classList.add(suggestsItemSelector);

        suggestsItemEl.innerHTML = `
        <a href="/${suggestItem.serial ? 'tvseries' : 'movies'}/${suggestItem.name_id}" class="${suggestItemSelector}">
            <img
                class="${suggestItemImgSelector}"
                src="${imageLink}"
                alt="${suggestItem.name_rus}"
            >
            <div class="${suggestItemTitleSelector}">${suggestItem.name_rus}</div>
            <div class="suggestItem__year">${suggestItem.year}</div>
        </a>
    `;

        suggestsItemEl.addEventListener('click', function () {
            let suggestExtend = document.createElement('div');
            suggestExtend.style.display = 'none';

            suggestExtend.innerHTML = `
            <input name="extra" value="${suggestItem.extra}">   
            <input name="source" value="${suggestItem.source}">   
            <input name="userInput" value="${inputEl.value}">
        `;

            searchFormEl.appendChild(suggestExtend);
            searchFormEl.submit();
        })

        return suggestsItemEl;
    }


}
