define([
    'callbackCT'
], function (callbackCT) {
    'use strict';

    let state = {};

    function getVL(dataId, cb = () => {}, err = () => {}) {

        if (!!state.playerXhrTemp && state.playerXhrTemp.readystate !== 4) {
            state.playerXhrTemp.abort();
        }
        callbackCT('/ajax/video', function (resp) {

            state.playerXhrTemp = $.ajax({
                type: 'GET',
                dataType: 'json',
                url: '/ajax/video/' + dataId + '?client_time=' + resp,
                success: function (resp) {

                    state.playerXhrTemp = null;
                    if (resp.error) {
                        err(resp.error);
                        return false;
                    }
                    if (!resp.url) {
                        setTimeout(function () {
                            getVL(model, dataId);
                        }, 3000);
                        return false;
                    } else {

                        cb({
                            src: resp.url,
                            // poster: (!resp.images || !resp.images.length) ? 'assets/poster.gif' : resp.images[0]
                        });
                    }
                },
                error: function (error) {
                    setTimeout(function () {
                        state.getVL(model, dataId);
                    }, 3000);
                    return false;
                }
            });
        });
    }
    return getVL;
});
