import videoLink from "./videoLink";
import Flickity from "flickity";
import videojs from "video.js";
import config from "./playerConfig";
import 'videojs-hotkeys'

const VAST = 'https://raw.githubusercontent.com/InteractiveAdvertisingBureau/VAST_Samples/master/VAST%203.0%20Samples/Inline_Companion_Tag-test.xml';


function playBySrc(player, sourceId) {
    videoLink(sourceId, ({src}) => {
        // src = 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';
        player.src({type: "video/mp4", src});
        player.play()

    }, (err) => {
        console.error(err);
    });
}

let playerEl = document.querySelector('#player');

if (playerEl) {

    let episodesEl = document.querySelector('.carousel_episodes');

    let episodes = null;

    if (episodesEl) {

        episodes = new Flickity(episodesEl, {
            accessibility: false,
            freeScroll: true,
            contain: true,
            // groupCells: '80%',
            groupCells: true,
            friction: .2,
            pageDots: false,
            cellAlign: 'left'
        });

    }

    let buttonMoveEl = document.querySelectorAll('.videoContainer__button_move');
    let buttonTrailerEl = document.querySelectorAll('.videoContainer__button_trailer');

    let videoId = buttonMoveEl[0] ? buttonMoveEl[0].dataset.id : null;
    let trailerId = buttonTrailerEl[0] ? buttonTrailerEl[0].dataset.id : null;

    let dataId = videoId || trailerId;
    let eventFiringId = null;
    let play_mode = videoId ? 'video' : 'trailer';

    let player = videojs('#player', config);
    player.one('play', () => {
        window.yaCounter87985799.reachGoal('player_video_start')
    })
    player.on('error', () => window.yaCounter87985799.reachGoal('player_error', {
        URL: document.location.href,
        msg: player.error().message
    }))
    player.on('waiting', () => {
        eventFiringId = setTimeout(() => {
            window.yaCounter87985799.reachGoal('player_buffered', {URL: document.location.href})
        }, 5000);
    })
    player.on('playing', ()=>{
        if(eventFiringId){
            clearTimeout(eventFiringId)
        }
    })
    videoLink(dataId, (data) => {
        let src = data.src;
        // let src = 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';

        player.src({type: "video/mp4", src});

    }, (err) => {
        console.log(err);
    });


    if (episodes) {

        let allEpisodes = document.querySelectorAll('.episodeItem');
        document.querySelectorAll('.episodeLink').forEach(item=>{
            item.addEventListener('click',(e)=>{
                e.preventDefault()
            })
        })
        episodes.on('staticClick', (event, pointer, cellElement, cellIndex) => {

            allEpisodes.forEach((item) => {

                item.classList.remove('episodeItem_active');
            })

            let episodeEl = cellElement.querySelector('.episodeItem');

            episodeEl.classList.add('episodeItem_active');

            let dataId = episodeEl.dataset.id;

            buttonMoveEl[0].dataset.episode = episodeEl.dataset.episode || cellIndex;
            buttonMoveEl[0].dataset.id = dataId;
            let h2_title_player = document.querySelector('.js-currentHeaderEpisode');
            if(h2_title_player) {
                let span_currentEpisode = h2_title_player.querySelector('span.js-currentEpisode');

                if (!span_currentEpisode) {
                    let newSpan = document.createElement('span');
                    newSpan.className = 'js-currentEpisode';
                    h2_title_player.appendChild(document.createTextNode(' '));
                    h2_title_player.appendChild(newSpan);
                    h2_title_player.appendChild(document.createTextNode(' серию'));
                }
            }

            let currentEpisodeEl = document.querySelectorAll('.js-currentEpisode');

            currentEpisodeEl.forEach((currentEpisodeElItem) => {
                currentEpisodeElItem.innerHTML = episodeEl.dataset.episode || cellIndex;
            });




            player.pause();

            window.history.pushState("", "", episodeEl.dataset.episodeLink);

            videoLink(dataId, (data) => {

                let src = data.src;

                player.src({type: "video/mp4", src: src});
                player.play();

            }, (err) => {
                console.log(err);
            });


        });

    }


    buttonMoveEl && buttonMoveEl.forEach((buttonMoveElItem) => {
        buttonMoveElItem.addEventListener('click', () => {

            if (dataId === videoId) {
                player.play();
            } else {
                dataId = videoId
            }

            playBySrc(player, videoId)

        });
    })


    buttonTrailerEl && buttonTrailerEl.forEach((buttonTrailerElItem) => {
        buttonTrailerElItem.addEventListener('click', () => {

            if (dataId === trailerId) {
                player.play();
            } else {
                dataId = trailerId
            }

            playBySrc(player, trailerId)

        })
    })

}
