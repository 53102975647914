import lazyImage from './lazyLoad';

let process = false;

let twig = require('twig');
twig.extendFilter("get_type", function(isFilm) {

    if (isFilm) {
        return 'tvseries';
    }
    return 'movies';
});

twig.extendFilter("duration_iso", function(value) {
    return ''
});

let template = require('parts/galleryItem.twig')


let paginationContainerEl = document.querySelector('.tiles__dataLoad');

let render = (items) => {

    items.forEach((item) => {

        let elem = document.createElement('li');
        elem.classList.add('tiles__item');

        elem.innerHTML = template(item);

        paginationContainerEl.appendChild(elem);

        lazyImage(elem.querySelectorAll("img[data-src]"));
    });



}

if (paginationContainerEl) {

    let totalPages = paginationContainerEl.dataset.total;
    let currentPage = +paginationContainerEl.dataset.current;
    let url = paginationContainerEl.dataset.url;


    let scrollHandler = function() {

        if (process || !totalPages || (currentPage >= totalPages)){
            return;
        }

        if (!currentPage) {return};

        if (window.scrollY >= (paginationContainerEl.offsetHeight - window.innerHeight) * 0.9) {
            process = true;

            fetch(url + '?page=' + (currentPage + 1) + '&ts=' + (new Date()).getTime(),{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-requested-with': 'XMLHttpRequest'
                },
            })
            .then(response => response.json())
            .then(data => {

                if (data.items) {
                    render(data.items);
                }

                currentPage++;
                process = false;
            }).catch(() => {
                process = false;
            });

        }

    };

    window.addEventListener('scroll', function(e){
        scrollHandler();
    });

}


