export default  {
    preload: 'none',
    nativeControlsForTouch: true,
    controls: true,
    plugins: {
        hotkeys: {
            volumeStep: 0.15,
            seekStep: 25
        }
    }
};
