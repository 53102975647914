/**
 * Fallback to localStorage
 **/
if (typeof localStorage == "undefined" && typeof FileSystem == "function") {
    var fileSyObj = new FileSystem();
    var fileName  = curWidget.id + "_localStorage.db";
    var lStorage  = {};
    var changed   = false;

    // load or init localStorage file
    var fileObj = fileSyObj.openCommonFile(fileName, "r+");
    if (fileObj != null) {
        try {
            lStorage = JSON.parse(fileObj.readAll());
        } catch(e) { }
    } else {
        fileObj = fileSyObj.openCommonFile(fileName, "w")
        fileObj.writeAll("{}");
    }
    fileSyObj.closeCommonFile(fileObj);

    // Save storage
    lStorage.saveFile = function(delay) {
        if (changed && typeof JSON == 'object') {
            var $self = this;
            var save  = function() {
                fileObj = fileSyObj.openCommonFile(fileName, "w")
                fileObj.writeAll(JSON.stringify($self));
                fileSyObj.closeCommonFile(fileObj);
                changed = false;
            }
            if (typeof delay != 'undefined' && delay)
                setTimeout(save, 100);
            else
                save();
        }
    }

    lStorage.setItem = function(key, value) {
        changed = true;
        this[key] = value;
        this.saveFile(true);
        return this[key];
    }

    lStorage.getItem = function(key) {
        return this[key];
    }

    window.localStorage = lStorage;
}


import './es5-shim';
import 'promise';

import 'flickity/css/flickity.css';
import 'video.js/dist/video-js.css';
import './styl/index.styl'

// import './js/share';

import Flickity from 'flickity';

import './js/pagination';

import './js/suggests';

let sliders = document.querySelectorAll('.' + 'carousel');

sliders = [...sliders];


sliders.forEach((sliderItemEl) => {

    /** doc
     * small https://github.com/metafizzy/flickity#options
     * full https://flickity.metafizzy.co/options.html
     * */

    if (!sliderItemEl.classList.contains('carousel_episodes')) {
        let slider = new Flickity(sliderItemEl, {
            accessibility: false,
            freeScroll: true,
            // cellAlign: 'left',
            contain: true,
            // groupCells: '80%',
            groupCells: true,
            friction: .2,
            pageDots: false,
            cellAlign: 'left'
            // wrapAround: true
        });

        // let enable = true;
        //
        // sliderItemEl.addEventListener('wheel', (e) => {
        //
        //     if (e.deltaX !== 0 && enable) {
        //
        //         enable = false;
        //
        //         setTimeout(() => { enable = true; }, 800);
        //
        //         if (e.deltaX > 0) {
        //             slider.next();
        //         } else {
        //             slider.previous();
        //         }
        //     }
        //
        //
        // })
    }


});


// лэзилоуд
import lazyImage from './js/lazyLoad';
lazyImage(document.querySelectorAll("img[data-src]"));



import './js/player'




// FILTERS

let filtersEl = document.querySelector('.filters');

if (filtersEl) {

    let genre = filtersEl.dataset.genre;
    let year = filtersEl.dataset.year;
    let country = filtersEl.dataset.country;
    let rating = filtersEl.dataset.rating;
    let studio = filtersEl.dataset.studio;
    let sort = filtersEl.dataset.sort;
    let url = filtersEl.dataset.url;

    let genreEl = filtersEl.querySelector('#genre');

    genreEl.addEventListener('change', (e) => {
        updateUrl(e.target.value, year, country, rating, studio, sort);
    });

    let yearEl = filtersEl.querySelector('#year');

    yearEl.addEventListener('change', (e) => {
        updateUrl(genre, e.target.value, country, rating, studio, sort);
    });

    let countryEl = filtersEl.querySelector('#country');

    countryEl.addEventListener('change', (e) => {
        updateUrl(genre, year, e.target.value, rating, studio, sort);
    });

    let ratingEl = filtersEl.querySelector('#rating');

    ratingEl.addEventListener('change', (e) => {
        updateUrl(genre, year, country, e.target.value, studio, sort);
    });

    let studioEl = filtersEl.querySelector('#studio');

    studioEl.addEventListener('change', (e) => {
        updateUrl(genre, year, country, rating, e.target.value, sort);
    });

    let sortEl = filtersEl.querySelector('#sort');

    sortEl.addEventListener('change', (e) => {
        updateUrl(genre, year, country, rating, studio, e.target.value);
    });

    function updateUrl (genre, year, country, rating, studio, sort) {

        // console.log(genre, year, country, rating, studio, sort);

        let link = url;

        let update = '/filter';

        if (genre) {
            update += '/genre-' + genre;
        }

        if (year) {
            update += '/year-' + year;
        }

        if (country) {
            update += '/country-' + country;
        }

        if (rating) {
            update += '/rating-' + rating;
        }

        if (studio) {
            update += '/studio-' + studio;
        }

        if (sort) {
            update += '/sort-' + sort;
        }

        if (update === '/filter') {
            update = ''
        }

        window.location.href = link + update;
    }
}

let searchToggleEl = document.querySelector('.searchToggle');
let headerEl = document.querySelector('.header');

searchToggleEl && searchToggleEl.addEventListener('click', () => {
    headerEl.classList.toggle('header_search');
});

let menuToggleEl = document.querySelector('.menuToggle');
menuToggleEl && menuToggleEl.addEventListener('click', () => {
    headerEl.classList.toggle('header_menu');
});


let scrollToPlayer = document.querySelectorAll('.scrollToPlayer');

scrollToPlayer.forEach(function (scrollToPlayerEl) {
    scrollToPlayerEl.addEventListener('click', () => {
        let playerEl = document.querySelector("#player");
        playerEl && playerEl.scrollIntoView({block: "center", behavior: "smooth"});
    })
})

// Profile popup menu
const profilePopup = document.querySelector('.profilePopup');
const profileButton = document.querySelector('.profileButton');
profileButton && profileButton.addEventListener('click', () => {
    profilePopup.classList.toggle('visible');
    console.log('first')
});

// Upload video
const uploadForm = document.querySelector('.uploadForm');
const uploadInput = document.querySelector('#uploadInput');
const uploadIndicator = document.querySelector('.uploadIndicator');
const uploadSuccessMessage = document.querySelector('.uploadSuccessMessage');

const uploadFormSubmit = (event) => {
    const file = uploadInput.files[0];
    const fileSize = file.size;

    const delayRatio = Math.random() + 1
    let delay = 10000 * delayRatio; // 500 МБ и больше
    if (fileSize < 100 * 1024 * 1024) delay = 5000 * delayRatio; // 100 МБ
    if (fileSize < 10 * 1024 * 1024) delay = 1000 * delayRatio; // 10 МБ

    uploadIndicator.style.display = 'block';
    uploadForm.remove();

    setTimeout(() => {
        uploadIndicator.style.display = 'none';
        uploadSuccessMessage.style.display = 'block';
    }, delay);
};

uploadForm && uploadForm.addEventListener('change', uploadFormSubmit)
